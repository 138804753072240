import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import "./styles/Landing.css";

const Nav = (props) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > window.innerHeight * 0.6);
    });
  }, []);

  const isRoot = props.location.pathname === "/";
  const style = isRoot ? { color: "#ffffff" } : { color: "#000000" };
  const logo = isRoot ? "assets/logo-white.svg" : "assets/logo.svg";
  const navClass = isRoot ? "nav sticky" : "nav";

  return (
    <div
      className={scroll && isRoot ? navClass + " scrolled" : navClass}
      style={{ zIndex: 5 }}
    >
      <Link className="nav-logo nav-text nav-title" to="/">
        <div style={style}>
          <img src={logo} style={{ height: "5vh" }}></img>
        </div>
      </Link>

      <Link className="nav-link" id="schedule-link" to="/about" style={style}>
        <div className="nav-text">About</div>
      </Link>

      <a className="nav-link" href="/#services" style={style}>
        <div className="nav-text">Services</div>
      </a>

      <a className="nav-link" href="/#experience" style={style}>
        <div className="nav-text">Experience</div>
      </a>

      <a className="nav-link" href="/#footer" style={style}>
        <div className="nav-text">Contact</div>
      </a>
    </div>
  );
};

export default withRouter(Nav);
