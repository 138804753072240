import React from "react";
import { withRouter } from "react-router-dom";
import "./styles/Landing.css";
import {
  AiOutlineMail,
  AiOutlineWhatsApp,
  AiOutlineLinkedin,
} from "react-icons/ai";
import { GiBattleship } from "react-icons/gi";

const Footer = (props) => {
  const isRoot = props.location.pathname === "/";
  const style = isRoot
    ? { backgroundColor: "#f8f8f8" }
    : { backgroundColor: "#ffffff" };

  return (
    <section className="footer" id="footer" style={{ ...style, width: "100%" }}>
      <div className="footer-div  footer-div-img">
        <img src={"assets/logo.svg"} style={{ height: "100px" }}></img>
      </div>
      <div className="footer-div" style={{ textAlign: "center" }}>
        <div className="footer-strong">We set your ship sailing faster</div>
        <div className="footer-text">
          <AiOutlineMail style={{ verticalAlign: "middle" }}></AiOutlineMail>{" "}
          sachin@dmetmarineservices.com
          <br></br>
          <AiOutlineWhatsApp
            style={{ verticalAlign: "middle" }}
          ></AiOutlineWhatsApp>{" "}
          971.50.8560324<br></br>
          <AiOutlineLinkedin
            style={{ verticalAlign: "middle" }}
          ></AiOutlineLinkedin>{" "}
          <a
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: "black",
            }}
            target="_blank"
            href={"https://www.linkedin.com/in/sachinsaxenamarineoffshoreexe/"}
          >
            Connect
          </a>
        </div>
      </div>
      <div className="footer-div">
        <GiBattleship></GiBattleship>
        <br></br>
        Dubai<br></br>
        UAE
      </div>
    </section>
  );
};

export default withRouter(Footer);
