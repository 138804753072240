export const whyUsCopy = [
  {
    image: "icons/zero-harm.png",
    title: "Zero Harm",
    description:
      "Our philosophy in terms of safety and environment. We take pride in quality and on-time execution.",
  },
  {
    image: "icons/all-in-one.png",
    title: "One Stop Solution",
    description:
      "We are an integrated solution provider for all of your vessel's needs.",
  },
  {
    image: "icons/team.png",
    title: "Customer Focus",
    description:
      "We follow a simple rule that our customer is king and the most valuable person we will ever come in contact with.",
  },
  {
    image: "icons/cost.png",
    title: "Assured Cost Saving",
    description:
      "We will save your docking and project management cost by at least 10%",
  },
  {
    image: "icons/innovation.svg",
    title: "Innovative Solutions",
    description:
      "We have fostered partnerships with innovative firms at the cutting-edge of the industry.",
  },
  {
    image: "icons/workforce.png",
    title: "Management Expertise & Workforce",
    description:
      "Our management team has successfully overseen projects of epic proportions.  We have access to large qualified and shipyard experienced manpower",
  },
];

export const verticalsCopy = [
  {
    image: "verticals/drydock.jpg",
    title: "Maritime Consultancy",
    bullets: [
      "Shipyard consultancy: Greenfield, brownfield & turnaround.",
      "Feasibility projects.",
      "Drydocking management: A to Z starting from spec writing through execution, to sea trial and final settlement with shipyards",
    ],
  },
  {
    image: "verticals/partnership.jpg",
    title: "Strategy & Operations Consultancy",
    bullets: [
      "Organization & project turnaround",
      "Workshop & production flow optimization",
      "Productivity enhancement & cost control",
      "Safety, quality & management consultancy",
    ],
  },
  {
    image: "verticals/team.jpg",
    title: "Project Management",
    bullets: [
      "Ship/rig MRO, new build & conversion",
      "Offshore, Oil & Gas, Energy Projects",
      "Green Technology",
      "BWTS, Scrubber and EGCS",
    ],
  },
  {
    image: "verticals/design.jpg",
    title: "Engineering Services",
    description:
      "Meeting all regulatory and classification requirements: Basic design, structural design, detail engineering, shop/fabrication drawing, 3D modelling, piping design, mechanical design, electrical design, lifting study, corrosion engineering.",
  },
  {
    image: "verticals/efficiency.jpg",
    title: "Inspections &amp; Surveys",
    bullets: [
      "Pre purchase and condition surveys",
      "Pre Charter technical inspections",
      "Vessel valuation surveys",
      "Damage surveys and investigations",
      "Bunker surveys",
      "Pre-vetting surveys",
    ],
  },
  {
    image: "verticals/client.jpg",
    title: "Training Services",
    bullets: [
      "Coaching and mentoring",
      "Teambuilding and motivational sessions/speeches",
    ],
  },
];

export const firms = [
  {
    name: "Dyrdocks World Dubai",
    image: "experience/drydocks.png",
  },
  {
    name: "NKOM Qatar",
    image: "experience/nkom.png",
  },
  {
    name: "Acomarit Glasgow",
    image: "experience/acomarit.png",
  },
  {
    name: "Sanoyas",
    image: "experience/sanoyas.png",
  },
  {
    name: "Sumitomo Shipyard",
    image: "experience/sumito.png",
  },
];

export const partners = [
  {
    name: "Yeke",
    image: "partners/yeke.png",
  },

  {
    name: "Goltens",
    image: "partners/goltens.png",
  },
];

export const consultants = [
  {
    name: "Boston Consulting Group",
    image: "partners/bcg.png",
  },
  {
    name: "Third Bridge",
    image: "experience/thirdbridge.png",
  },
  {
    name: "AlphaSights",
    image: "partners/alphasights.png",
  },
  {
    name: "Guidepoint",
    image: "partners/guidepoint.png",
  },
  {
    name: "proSapient",
    image: "partners/prosapient.png",
  },
  {
    name: "Dialetica",
    image: "partners/dialectica.png",
  },
];

export const portfolio = [
  {
    image: "portfolio/dolwin.jpg",
    title: "Dolwin Beta",
    text:
      "World’s largest HVDC platform involving a world record heavy lifting operation. First of its kind in MENA region which put Dubai on the world map for executing a Mega Project involving over 9 million man hours.",
  },
  {
    image: "portfolio/turret.jpg",
    title: "Turret for FLNG Prelude",
    text:
      "Marvel of engineering with extremely high precision fabrication done using cuttinge-edge technology. World’s largest turret for the world’s largest FLNG and vessel at the time of fabrication.",
  },
  {
    image: "portfolio/frade.jpg",
    title: "Frade conversion to FPSO",
    text:
      "Another mega project at epic scale involving in excess of 13 million man hours. Arguably the largest conversion to date with impeccable safety and quality records.",
  },
];
