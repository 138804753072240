import React from "react";
import "./styles/About.css";

// const Header = () => (
//   <section className="about-header">
//     <h1 className="about-header-text">About Us</h1>
//   </section>
// );
const AboutText = () => (
  <section className="body-section">
    <h2>About Us</h2>
    <div className="about-container">
      <div className="about-description">
        Passionate about excellence in whatever we do, DMET Marine services is a
        strategic support partner to the maritime industry providing Drydocking,
        MRO, new build project management integrated with engineering, supplies,
        logistics and other related services.
        <br></br>
        <br></br>
        Our <strong>vision</strong>, quite simply, is to be role model to the
        region in marine services offering our expertise in drydocking, project
        management, maintenance, repair, overhaul, engineering and logistics to
        global maritime and offshore clientele.
        <br></br>
        <br></br>
        Our goal is to combine safety, quality and production performance of
        highest standard with best of technology and professionalism to add
        value and give back to our stake holders. We don’t make tall claims but
        assure you that we will save at least 10% cost in your drydocking, MRO &
        new build project management.
        <br></br>
        <br></br>
        <h4>We set your ship sailing faster.</h4>
      </div>
      <div className="about-profile">
        <div style={{ flex: 1 }}>
          <img src="assets/dp.png" className="about-dp"></img>
          <div className="about-dp-title">
            <h3>Sachin Saxena</h3>
            <h4>CEO & Managing Partner</h4>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <p className="about-profile-text">
            <strong>30+ years of experience</strong>
          </p>
          <p className="about-profile-text">
            {/* Founder - Smart Hydrobotics, Dubai
            <br></br> */}
            COO - NKOM, Qatar
            <br></br>
            Vice President - Drydocks World, Dubai
            <br></br>
            Chief Engineer - ACOMARIT, Glasgow
            <br></br>
            BEng | MBA
          </p>
        </div>
      </div>
    </div>
  </section>
);

const Values = () => (
  <section style={{ backgroundColor: "#f8f8f8" }}>
    <div className="about-values">
      <h2 style={{ paddingBottom: "0.5em" }}>Our Values</h2>
      Safety, quality, environmental consciousness and on time delivery are
      basic needs for survival of a business today.
      <br></br> We strive to give our customers a unique experience in whatever
      we will do. <br></br>
      <br></br>Our core values are:<br></br>
      <br></br>
      <strong>
        “<u>D</u>aring”{" "}
      </strong>
      to be different from the crowd<br></br>
      <strong>
        “<u>M</u>indful”{" "}
      </strong>
      of customer needs and pains in whatever we do <br></br>
      <strong>
        "<u>E</u>mpowered”
      </strong>{" "}
      to challenge the status quo and be the change agent<br></br>
      <strong>
        “<u>T</u>rustworthy”{" "}
      </strong>
      on every promise we make<br></br>
      <br></br>
      Tribute to my Alma Mater, DMET is the word which evolves from our core
      values and hence reflection in the name{" "}
      <strong>“DMET Marine Services”</strong>
    </div>
  </section>
);

function About() {
  return (
    <div className="about">
      <AboutText></AboutText>
      <Values></Values>
    </div>
  );
}

export default About;
