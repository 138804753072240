import React, { useState, useEffect } from "react";

import "./styles/Landing.css";
import {
  whyUsCopy,
  verticalsCopy,
  firms,
  partners,
  portfolio,
  consultants,
} from "./copy";

const Splash = () => (
  <section className="hero">
    <div className="hero-overlay"></div>
    <video className="hero-video" autoPlay loop muted>
      <source src="assets/hero.mp4" type="video/mp4"></source>
    </video>
    <div className="hero-text">
      <h1>Your Strategic Partner In Drydock and Offshore Project Management</h1>
      <CTA />
    </div>
  </section>
);

const CTA = () => {
  const backgroundColor = "#428ecb";
  return (
    <a
      href="mailto:sachin@dmetmarineservices.com"
      style={{
        display: "inline-block",
        padding: "0.75em 4em",
        backgroundColor,
        color: "#fff",
        textDecoration: "none",
        borderRadius: "40px",
        transition: "background-color 0.3s ease-in-out",
      }}
      onMouseOut={(e) => {
        e.target.style.backgroundColor = backgroundColor;
      }}
    >
      <strong>
        <h3>Contact Us</h3>
      </strong>
    </a>
  );
};

const Header = () => (
  <section
    className="body-section"
    style={{ marginRight: "15%", marginLeft: "15%" }}
  >
    <h2>DMET Marine Services</h2>
    <p className="header-text">
      DMET Marine Services is a strategic support partner to the Maritime,
      Offshore, and Oil & Gas industries, providing consultancy services,
      drydocking, MRO, and new build project management integrated with
      engineering surveys, inspections, and other related services.
      <br></br>
      <br></br>
      Passionate about excellence and firm believers in ZERO HARM, we strive to
      combine safety, quality, and production performance to add value and
      delight our clients. We do not make tall claims, but we can save more than
      10% in costs for your drydocking, MRO, and new build project management.
    </p>
    <strong>We set your ship sailing faster.</strong>
    <br></br>
    <br></br>
    By challenging the status quo, simplifying processes, and inspiring the team
    to achieve the best possible results, we think outside the box to come up
    with innovative solutions that turn our clients into our business partners.
    We offer environmentally friendly and soundly engineered solutions for all
    maintenance and repair needs, saving both cost and time – a true recipe for
    delighting our clients."
  </section>
);

const WhyUs = () => {
  return (
    <section
      style={{ marginTop: "3%", marginBottom: "-3%" }}
      className="body-section"
    >
      <h2>Why Us</h2>
      <strong>Our Goal is simple – to ensure you meet yours.</strong>
      <div className="card-container">
        {whyUsCopy.map((v) => {
          return (
            <div className="points-card-block">
              <img className="services-img" src={v.image}></img>
              <div className="services-content">
                <h4 className="services-title">{v.title}</h4>
                <h5 className="points-text">{v.description}</h5>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const Verticals = () => {
  return (
    <section
      style={{ backgroundColor: "#f8f8f8", paddingTop: "3%" }}
      className="body-section"
      id="services"
    >
      <h2>Verticals and Services</h2>
      <div className="card-container">
        {verticalsCopy.map((v) => {
          return (
            <div className="services-card-block">
              <img
                className="services-img services-img-card"
                src={v.image}
              ></img>
              <div className="services-content">
                <h4 className="services-title">{v.title}</h4>
                <h5 className="services-text">
                  {v.description ? v.description : null}
                  {v.bullets ? (
                    <ul>
                      {v.bullets.map((b) => (
                        <li>{b}</li>
                      ))}
                    </ul>
                  ) : null}
                </h5>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

const Testimonials = () => {
  const Portfolio = () => {
    return portfolio.map((e, i) => {
      if (i % 2 === 0) {
        return (
          <div className="portfolio-block">
            <img className="portfolio-img" src={e.image}></img>
            <div className="portfolio-text portfolio-text-right">
              <h4>{e.title}</h4>
              <h5 className="portfolio-text">{e.text}</h5>
            </div>
          </div>
        );
      } else {
        return (
          <div className="portfolio-block">
            <div className="portfolio-text">
              <h4>{e.title}</h4>
              <h5 className="portfolio-text left">{e.text}</h5>
            </div>
            <img className="portfolio-img" src={e.image}></img>
          </div>
        );
      }
    });
  };

  return (
    <section
      style={{
        backgroundColor: "#f8f8f8",
        paddingBottom: "4%",
        paddingTop: "4%",
      }}
      className="body-section"
      id="experience"
    >
      <div>
        <h3>
          Our management and team members have led the delivery of projects at
          epic scale, including...
        </h3>
        {<Portfolio></Portfolio>}
      </div>
    </section>
  );
};

const Partners = () => {
  return (
    <section
      style={{
        backgroundColor: "#ffffff",
      }}
      className="body-section"
      id="experience"
    >
      <h3>
        We bring experience from the largest firms in the industry, such as...
      </h3>
      <div className="firm-container">
        {firms.map((firm) => {
          return <img className="firm-logo" src={firm.image} alt={firm.name} />;
        })}
      </div>
      <div style={{ padding: "4% 0" }}>
        <h3>Our trusted business partners include...</h3>
        <div className="firm-container">
          {partners.map((partner) => {
            return (
              <img
                className="firm-logo"
                src={partner.image}
                alt={partner.name}
              />
            );
          })}
        </div>
      </div>
      <div style={{ padding: "4% 0" }}>
        <h3>We have provided consultancy services through...</h3>
        <div className="firm-container">
          {consultants.map((partner) => {
            return (
              <img
                className="firm-logo"
                src={partner.image}
                alt={partner.name}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

const Landing = () => {
  const [_scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > window.innerHeight * 0.7);
    });
  }, []);

  // const { register, handleSubmit, errors } = useForm();

  return (
    <div style={{ backgroundColor: "#ffffff", width: "100%" }}>
      <Splash></Splash>
      <Header></Header>
      <Verticals></Verticals>
      <WhyUs></WhyUs>
      <Testimonials></Testimonials>
      <Partners></Partners>
      {/* <Contact
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
      ></Contact> */}
    </div>
  );
};

export default Landing;
